import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Blog = () => (
  <Layout>
    <SEO title="Kevin Young: Blog" />
    <section>
      <div className="boxed-container">
        <div className="single-image">
          <Image /><br /><br />
          <div className="centre"><h3>COMING SOON...</h3></div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Blog

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "blog.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}